p-floatlabel {
  .p-inputtext,
  p-multiselect .p-multiselect {
    @apply h-12 pt-5 pb-1.5  w-full;
  }

  .p-cascadeselect-label {
    @apply py-0 px-3;
  }

  .p-float-label input:focus ~ label,
  .p-float-label input.p-filled ~ label,
  .p-float-label textarea:focus ~ label,
  .p-float-label textarea.p-filled ~ label,
  .p-float-label .p-inputwrapper-focus ~ label,
  .p-float-label .p-inputwrapper-filled ~ label {
    top: 1rem;
    left: 12px;
  }

  .p-multiselect .p-multiselect-trigger {
    @apply mb-3 mr-1;
  }

  p-multiselect .p-multiselect {
    @apply h-12;
  }
}

.p-float-label > p-dropdown {
  input.p-placeholder {
    @apply focus:opacity-100 text-neutral-1000;
  }

  input.p-placeholder::placeholder {
    opacity: 0;
  }
}

.p-calendar .p-datepicker {
  @apply min-w-[unset];
}

.p-datepicker {
  @apply px-4 pt-0 rounded-xl;

  .p-datepicker-header {
    @apply p-0 h-13;
  }

  table td {
    @apply p-0;
  }

  table td > span {
    @apply w-9 h-9 m-[1px];
  }

  table td.p-datepicker-today > span {
    @apply text-blue-600 border border-blue-600 bg-transparent;
  }

  table td > span.p-highlight {
    @apply text-white bg-blue-600;
  }
}

p-tag {
  .p-tag {
    @apply py-[2px] px-2 text-sm font-medium bg-blue-200 text-blue-800;
  }

  .p-tag-success {
    @apply bg-green-200 text-green-800;
  }

  .p-tag-warning {
    @apply bg-orange-200 text-orange-800;
  }

  .p-tag-secondary {
    @apply bg-neutral-300 text-neutral-900;
  }

  .p-tag-info {
    @apply bg-cyan-200 text-cyan-800;
  }

  .p-tag-danger {
    @apply bg-red-200 text-red-800;
  }
  .p-tag-contrast {
    @apply bg-indigo-200 text-indigo-800;
  }
}

p-multiselect {
  .p-multiselect {
    @apply h-9 items-center;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    @apply py-[0.5rem];
  }

  .p-multiselect-header {
    @apply bg-[unset] h-11;
    .p-multiselect-filter {
      @apply border-none indent-3;
      box-shadow: none !important;
    }
  }

  .p-multiselect-filter {
    @apply h-10 px-2 py-0;
  }

  .p-multiselect-filter-icon {
    @apply left-0;
  }
}

p-sidebar {
  .p-sidebar .p-sidebar-content {
    @apply pl-0;
  }
  .p-sidebar .p-sidebar-header {
    @apply py-3;
  }
}

p-inputotp {
  .p-inputotp-input {
    @apply w-11;
  }
}

.p-checkbox {
  @apply h-5 w-5 items-center;
}

.p-checkbox-box {
  @apply w-5 h-5 border-[1px] rounded;
}

.ng-invalid.ng-touched {
  @apply border-red-600;
  & > .p-dropdown:not(.p-dropdown-open) {
    @apply border-red-600;
  }

  & > .border {
    @apply border-red-600;
  }

  & > .p-password > .p-password-input {
    @apply border-red-600;
  }
}

p-inputswitch {
  @apply flex;
  .p-inputswitch {
    @apply h-5 w-9;
  }

  .p-inputswitch .p-inputswitch-slider:before {
    @apply left-[1px] w-4 h-4 -mt-2;
  }

  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    @apply translate-x-4;
  }
}

p-table {
  .p-datatable {
    @apply border rounded-sm overflow-hidden;
  }

  .p-datatable .p-datatable-thead > tr > th {
    @apply h-11 px-3 py-0;
  }

  .p-datatable .p-datatable-tbody > tr > td {
    @apply h-11 px-3 py-0;
  }

  .p-datatable .p-datatable-thead > tr > th {
    @apply font-medium bg-neutral-200;
  }

  .p-datatable .p-datatable-tbody > tr:last-child > td {
    border: 0 !important;
  }

  .p-datatable-loading-overlay {
    @apply bg-neutral-alpha-200A top-11 h-[calc(100%_-_44px)];
  }

  .p-datatable .p-datatable-footer {
    @apply border-none bg-[unset] p-0;
  }

  .p-datatable-wrapper::-webkit-scrollbar {
    @apply w-3 h-[unset];
  }
}

// P-DROPDOWN
p-dropdown {
  .p-dropdown-label {
    @apply pl-3 pr-0.5 flex items-center;
  }

  .p-dropdown-trigger-icon {
    @apply text-neutral-1000 text-6;
  }
}

.p-dropdown .p-dropdown-panel {
  @apply relative top-1;
}

.p-dropdown-label,
.p-multiselect-label {
  @apply px-3;
}

.p-dropdown > .p-inputtext:enabled:focus {
  @apply shadow-none;
}

.p-dropdown-item {
  @apply flex items-center gap-2;
}

p-multiselect {
  .p-multiselect-trigger-icon {
    @apply text-neutral-1000 text-6;
  }

  .p-multiselect-close {
    @apply hidden;
  }
}

.p-multiselect-filter {
  @apply h-8;
}

.p-dropdown-header {
  @apply bg-[unset] h-11 border-none;
  .p-dropdown-filter {
    @apply border-none indent-3;
    box-shadow: none !important;
  }

  .p-dropdown-filter-container {
    @apply h-full;
  }

  .p-dropdown-filter-container > input {
    @apply h-full;
  }

  .p-dropdown-filter-icon {
    @apply left-0;
  }
}

.p-dropdown-panel .p-dropdown-items {
  @apply py-0;
}

// P-DIALOG
.p-dialog {
  .p-dialog-title {
    @apply text-5 font-semibold text-neutral-1000;
  }

  .p-dialog-header {
    @apply p-6;
  }

  .p-dialog-footer {
    @apply p-4;
  }

  .p-dialog-footer button {
    @apply mr-2;
  }
}

p-dynamicdialog {
  .p-dialog-header {
    @apply p-6;
  }

  .p-dialog-content {
    @apply p-6 pb-0;
  }

  .p-dialog-content {
    @apply pt-0;
  }

  .p-dialog-title {
    @apply text-5;
  }
}

p-confirmdialog {
  .p-button {
    @apply outline-none shadow-none;
  }

  .p-button-label {
    @apply font-medium;
  }

  .p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    @apply m-0;
  }

  .p-dialog .p-dialog-footer {
    @apply flex items-center gap-3 justify-end;
  }

  .p-dialog .p-dialog-footer button {
    @apply m-0;
  }
}

input::placeholder {
  @apply text-neutral-400;
}

p-avatar {
  @apply flex;
  .p-avatar {
    & > img {
      @apply object-cover;
    }
  }
}
p-breadcrumb {
  .p-breadcrumb {
    @apply border-none p-0 px-5 w-full;
  }
}

.p-tooltip .p-tooltip-text {
  @apply bg-white text-2 px-3 py-2 text-neutral-1000 shadow-sm border border-gray-200 font-medium rounded-sm;
}

.p-overlaypanel .p-overlaypanel-content {
  @apply p-0;
}

.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  @apply border-r-white;
}

.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  @apply border-t-white;
}

.p-inputtext:enabled:focus,
.p-dropdown:not(.p-disabled).p-focus,
.p-multiselect:not(.p-disabled).p-focus {
  box-shadow:
    0 0 0 2px white,
    0 0 0 4px #2563eb;
}

// MENU
.p-menu {
  @apply py-2 px-1;
}

.p-menuitem-icon::before {
  @apply text-6 text-neutral-1000;
}

.p-menuitem-link {
  @apply h-9;
}

.error-color {
  .p-menuitem-text,
  .p-menuitem-icon::before {
    @apply text-red-600;
  }
}

// TREE
.p-tree-selectable {
  // @apply pl-2 pt-0;
  @apply pl-2 py-2;
}

.p-tree-loading-overlay {
  @apply bg-neutral-alpha-200A;
}

.p-tree .p-tree-loading-icon.p-icon {
  @apply w-5 h-5;
}

// .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
//   @apply w-auto;
// }

.p-inputwrapper-filled .p-treeselect.p-treeselect-chip .p-treeselect-label {
  @apply pb-6 pt-5.5;
}

.p-treeselect.p-treeselect-chip .p-treeselect-token {
  @apply py-0 px-3;
}

.p-treeselect-label-container {
  @apply h-[46px];
}

.ptree-disable-checkbox .p-checkbox-disabled {
  @apply hidden;
}

.p-tree-cursor-not-allowed {
  @apply cursor-not-allowed;
}

/* SIDEBAR */
.p-sidebar .p-sidebar-content {
  @apply p-0;
}

.custom-form-required,
.custom-calendar {
  .p-datepicker {
    @apply border-none;
  }

  .p-datepicker-header {
    @apply border-none;
  }

  .ng-invalid-field input {
    @apply border border-red-600;
  }
}

p-inputnumber,
.p-inputnumber {
  @apply w-full;
}

p-orderlist {
  .p-orderlist-controls {
    @apply hidden;
  }

  .p-orderlist-list-container {
    @apply border-none;
  }
}

.p-orderlist-item {
  height: fit-content !important;
}

.p-cascadeselect {
  .p-cascadeselect-trigger-icon {
    @apply text-neutral-1000;
  }
  .p-cascadeselect-trigger {
    @apply mb-3;
  }
}

p-selectbutton {
  .p-selectbutton {
    @apply bg-neutral-200 p-1 w-fit rounded-sm;
  }

  .p-button {
    @apply border-none h-7 bg-transparent shadow-none rounded;
  }

  .p-highlight {
    @apply bg-white text-primary-600;
  }

  .p-button-label {
    @apply font-medium;
  }
}
